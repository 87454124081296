const $inject = ['$scope', '$timeout', 'wnCommon'];

const sortOrgAlpha = (a, b) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
    
    return (nameA < nameB) ? -1 : (nameA > nameB) ? 1 : 0;
}
const sortOrgEventCount = (a, b) => b.count - a.count;
const getOrganizers = (events) => {
    const organizers = events.reduce(
        (acc, curr) => {
            if (typeof curr.organizer === 'string') {
                const organizer = curr.organizer.trim();

                if (typeof acc[organizer] === 'undefined') {
                    acc[organizer] = 1;
                } else {
                    acc[organizer] += 1;
                }
            }
            
            return acc;
        },
        {}
    )

    return Object
            .keys(organizers)
            .map((name) => ({
                title: `(${ organizers[name] }) ${ name }`,
                name: name,
                count: organizers[name]
            }))
            .sort(sortOrgAlpha)
}
const getCategories = (events, category) => {
    const categories = events.reduce(
        (acc, curr) => {
            if (typeof curr[category] === 'string') {
                const categoryName = curr[category].trim();

                if (typeof acc[categoryName] === 'undefined') {
                    acc[categoryName] = 1;
                } else {
                    acc[categoryName] += 1;
                }
            }
            
            return acc;
        },
        {}
    )
    
    return Object
            .keys(categories)
            .map((name) => ({
                title: `(${ categories[name] }) ${ name }`,
                name,
                count: categories[name]
            }))
            .sort(sortOrgAlpha)
}
let originalEvents = [];

function EventsListerCtrl(scope, timeout, common) {
    const categoryField = scope.settings.showCategory == 1 ? 'mainCatName' : 'category';
    var thisWeek = parseInt(moment().format('w'));

    scope.week = ['ma', 'ti', 'ke', 'to', 'pe', 'la'];
    scope.sunday = {
        name: 'su',
        events: []
    };
    scope.active = parseInt(moment().format('e'));
    scope.events = [];
    scope.organizers = [];
    scope.categories = [];
    scope.selectedCategories = {};
    scope.selectedOrganizers = {};
    scope.filters = {
        eventsCount: 0,
        organizer: '-1',
        category: '-1',
        selectedOrganizers: [],
        selectedCategories: [],
    }

    scope.week = scope.week.map((d) => {
        var obj = angular.copy(scope.sunday);
        obj.name = d;

        return obj;
    });
    
    scope.onFilterChange_ = () => {
        let events = originalEvents;
        
        if (scope.filters.organizer !== '-1') {
            const organizer = scope.organizers[parseInt(scope.filters.organizer)];
            
            events = events.filter(
                (event) => !!event.organizer && event.organizer.trim() === organizer.name
            )
        }
        
        if (scope.filters.category !== '-1') {
            const category = scope.categories[parseInt(scope.filters.category)];
            
            events = events.filter(
                (event) => !!event[categoryField] ? event[categoryField].trim() === category.name : false
            )
        }
        
        scope.events = events;
    }

    scope.onFilterChange = () => {
        const selectedOrganizers = scope.filters.selectedOrganizers;
        const selectedCategories = scope.filters.selectedCategories;
        let events = originalEvents;
        
        if (selectedOrganizers.length > 0) {            
            events = events.filter((event) => {
                if (!event.organizer) return false;
                
                return selectedOrganizers.indexOf(event.organizer.trim()) > -1;
            })
        }
        
        if (selectedCategories.length > 0) {
            events = events.filter((event) => {
                if (!event[categoryField]) return false;
                
                return selectedCategories.indexOf(event[categoryField].trim()) > -1;
            })
        }
        
        scope.events = events;
    }

    scope.onSelectedCategoriesChange = () => {
        scope.filters.selectedCategories = Object.keys(scope.selectedCategories)
            .filter(name => !!scope.selectedCategories[name]);
        scope.onFilterChange();
    }

    scope.onSelectedOrganizersChange = () => {
        scope.filters.selectedOrganizers = Object.keys(scope.selectedOrganizers)
            .filter(name => !!scope.selectedOrganizers[name]);
        scope.onFilterChange();
    }
    
    scope.toggleDropdown = (selector) => {
        const elem = document.querySelector(selector);

        elem.classList.toggle('open')
    }

    scope.loadEvents = () => {
        scope.$broadcast('wisenetwork.eventslister.status', 'loading');
        
        common.getEvents(scope.settings, (response) => {
            if (!Array.isArray(response)) {
                scope.$broadcast('wisenetwork.eventslister.status', 'loaded');
                return false;
            }

            var events = response.filter((e) => {
                if (scope.settings.viewMode == 1) {
                    return thisWeek === parseInt(moment(e.activityStartTime).format('w'));
                } else {
                    return true;
                }
            })
            
            if (scope.settings.viewMode === 1) {
                for (var i = 0; i < events.length; i++) {
                    var event = events[i];
                    var weekDay = parseInt(moment(event.activityStartTime).format('e'));
                    var day = weekDay ? scope.week[weekDay - 1] : scope.sunday;
                    
                    if (!scope.settings.limit || (scope.settings.limit && day.events.length < scope.settings.limit)) {
                        day.events.push(event);
                    }
                }
            } else {
                if (scope.settings.limit) {
                    scope.events = events.splice(0, scope.settings.limit);
                } else {
                    scope.events = events;
                } 
                
                originalEvents = scope.events;
                scope.organizers = getOrganizers(scope.events);
                scope.categories = getCategories(scope.events, categoryField);
                scope.filters.eventsCount = originalEvents.length;
            }

            scope.$broadcast('wisenetwork.eventslister.status', 'loaded');
        });
    };

    scope.loadEvents();

    // https://stackoverflow.com/questions/25016848/bootstrap-putting-checkbox-in-a-dropdown
    $(".checkbox-menu").on("change", "input[type='checkbox']", () => {
        $(this).closest("li").toggleClass("active", this.checked);
    });
    $(document).on('click', '.allow-focus', (evt) => {
        evt.stopPropagation();
    });
    
    $(document).on('click', (evt) => { 
        if(!$(evt.target).closest('.dropdown').length) {
            $('.dropdown.open').removeClass('open');    
        }
    })
}

EventsListerCtrl.$inject = $inject;

export default EventsListerCtrl