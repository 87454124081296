import EventsListerCtrl from './EventsListerCtrl.js'
import EventListCtrl from './EventListCtrl.js'
import listerTpl from './lister-tpl.html?raw'
import eventsListTpl from './eventslist-tpl.html?raw'
import eventsListWithImageTpl from './eventslist-withimage-tpl.html?raw'
import { angularModule } from '@common/angularConfigs.js'

const angular = window.angular

angularModule('wisenetwork.eventslister', ['ngSanitize','ui.bootstrap','wisenetwork.common'])
    .controller('EventsListerCtrl', EventsListerCtrl)
    .directive('wnEventsList', () => ({
        restrict: 'A',
        template: eventsListTpl,
        scope: { events:'<wnEventsList', settings:'<' },
        controller: EventListCtrl
    }))
    .directive('wnEventsListImg', () => ({
        restrict: 'A',
        template: eventsListWithImageTpl,
        scope: { events:'<wnEventsListImg', settings:'<' },
        controller: EventListCtrl
    }))
    .directive('wnEventsLister', () => ({
        restrict: 'A',
        template: listerTpl,
        scope: { settings: '<wnEventsLister' },
        controller: EventsListerCtrl
    }));
    
// this is an angular instance factory
window.createArticleEventsLister = function(elementId) {
    const moduleName = `eventsLister${ elementId }`;
    angular.module(moduleName, ['wisenetwork.eventslister'])

    angular.bootstrap(
        $(`#eventlister-${ elementId }`),
        [moduleName]
    );
}