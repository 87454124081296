function sortFactory(field, desc) {
    return function (a, b) {
        if (a[field] < b[field])
            return desc ? 1 : -1;
        if (a[field] > b[field])
            return desc ? -1 : 1;
        return 0;
    };
}

const $inject = ['$scope', '$sce', '$filter'];
const STATUS = {
    idle: 1,
    loading: 2,
    loaded: 3
}

function EventListCtrl(scope, sce, filter) {
    scope.loading = false;
    scope.status = STATUS.idle;

    scope.columns = {};
    scope.showCategory = 0;
    scope.showEndTime = false;
    scope.showDescription = false;
    scope.showShortDescription = false;

    if (scope.settings) {
        if (scope.settings.columns) {
            scope.settings.columns.forEach((value) => {
                if (value === 'endtime') {
                    scope.showEndTime = true;
                } else if (value === 'description') {
                    scope.showDescription = true;
                } else if (value === 'shortDescription') {
                    scope.showShortDescription = true;
                } else {
                    scope.columns[value] = true;
                }
            })
        }

        if (!!scope.settings.showCategory) {
            scope.showCategory = scope.settings.showCategory;
        }
    }

    scope.sort = {
        desc: false,
        field: 'startstamp'
    };

    scope.sortBy = function (fieldName) {
        if (fieldName === scope.sort.field)
            scope.sort.desc = !scope.sort.desc;

        scope.sort.field = fieldName;

        scope.events = scope.events.sort(sortFactory(scope.sort.field, scope.sort.desc));
    };

    scope.formatCategoryClass = function (category) {
        return !!category ?
                category
                .toLowerCase()
                .replace(/\s/g, '-')
                .replace(/ä/g, 'a')
                .replace(/ö/g, 'o')
                : '';
    };

    scope.localeTime = function (date) {
        return _locale(moment(date).format("HH:mm o'clock"));
    };

    scope.getEventLink = function (event) {
        // default
        var link = location.origin + '/?action=getcontent&type=viewevent&eventid=' + event.activityId;

        if (!!event.link && !!event.link.length) {
            link = event.link;
        } else if (!!event.registration && !event.inviteOnly) {
            link = event.applyLink;
        }

        return link;
    };

    scope.invitationLink = function (event) {
        return event.invitationLink != null ? event.invitationLink : event.applyLink;
    };

    scope.$on('wisenetwork.eventslister.loading', function (e, loading) {
        scope.loading = loading;
    });

    scope.$on('wisenetwork.eventslister.status', function (e, name) {
        if (STATUS[name]) {
            scope.status = STATUS[name];
        }
    });
}

EventListCtrl.$inject = $inject;

export default EventListCtrl